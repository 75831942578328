:root {
    --xvia-color-primary: #093089;
    --xvia-color-highlight: #566cd6;
    --xvia-color-gray: #f4f4f4;
    --xvia-color-title: #181b1d;
    --xvia-color-text: #777777;
    --xvia-color-text-secondary: #828892;
    --xvia-font-family: "DM Sans", sans-serif;
    --xvia-spacing-default: 16px;
    --xvia-spacing-tight: 12px;
    --xvia-spacing-extra-large: 24px;
}

*[class^="xvia-"] {
    box-sizing: border-box;
}

div.xvia-content-area {
    padding: 0;
}

/*********
 COMPONENTS
******/

/*
====> BUTTON COMPONENT
*/

.xvia-button {
    font-family: var(--xvia-font-family);
    display: inline-block;
    color: #fff;
    border-radius: 15px;
    font-weight: 700;
    font-size: 16px;
    border: 0;
    cursor: pointer;
    min-width: 120px;
    transition: background 0.2s ease-in-out;
    width: 100%;
}

.xvia-button---primary {
    background: var(--xvia-color-primary);
    padding: 0 15px;
    height: 60px;
    line-height: 60px;
}

.xvia-button---secondary {
    color: var(--xvia-color-text-secondary);
    padding: 0 15px;
    height: 60px;
    line-height: 60px;
    font-weight: 500;
}

.xvia-button---circle {
    border-radius: 100%;
    height: 37px;
    width: 37px;
    min-width: 37px;
    padding: 0px 10px;
    line-height: 0px;
}

.xvia-button:hover {
    background: var(--xvia-color-highlight);
}

.xvia-button---secondary:hover {
    background: var(--xvia-color-primary);
    color: white;
}

@media only screen and (max-width: 768px) {

    /* For mobile phones: */
    .xvia-button {
        width: 100%;
    }
}

/*
====> TITLE COMPONENT
*/

.xvia-title-h {
    font-family: var(--xvia-font-family);
    color: var(--xvia-color-title);
    font-weight: 700;
    margin-bottom: 20px;
}

.xvia-title-h5 {
    font-size: 18px;
    line-height: 1.2;
}

.xvia-title-h4 {
    font-size: 24px;
    line-height: 1.2;
}

.xvia-title-h3 {
    font-size: 28px;
    line-height: 1.3;
}

.xvia-title-h2 {
    font-size: 36px;
    line-height: 1.3;
}

.xvia-title-h1 {
    font-size: 48px;
    line-height: 1.3;
}

.xvia-title--no-margin {
    margin: 0;
}

/*
====> PARAGRAPH COMPONENT
*/

.xvia-paragraph {
    font-family: var(--xvia-font-family);
    color: var(--xvia-color-text);
    padding-right: 6px;
    line-height: 28px;
}

/*
====> TAG COMPONENT
*/

.xvia-tag {
    font-family: var(--xvia-font-family);
    font-size: 12px;
    color: #828892;
    text-decoration: none;
    font-weight: 500;
    line-height: 1;
    padding: 12px 14px;
    background: var(--xvia-color-gray);
    display: inline-block;
    margin-right: 11px;
    border-radius: 10px;
    margin-bottom: 16px;
    transition: all 0.2s linear 0s;
}

.xvia-tag:hover {
    background-color: var(--xvia-color-primary);
    color: #fff;
}

/*
====> STEP COMPONENT
*/

.xvia-step__header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.xvia-step__header h5 {
    margin: 0;
}

.xvia-step__content {
    margin-left: 58px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
}

.xvia-step--active .xvia-step__content {
    max-height: 100%;
}

.xvia-step__number {
    width: 36px;
    height: 36px;
    background: var(--xvia-color-gray);
    color: var(--xvia-color-text);
    display: inline-block;
    line-height: 37px;
    text-align: center;
    border-radius: 50px;
    margin-right: 16px;
    font-family: var(--xvia-font-family);
    font-size: 16px;
    font-weight: 700;
    transition: background 0.2s ease-in-out;
}

.xvia-step--active .xvia-step__number {
    background: var(--xvia-color-primary);
    color: white;
}

/*
====> ACCORDION COMPONENT
*/

.xvia-accordion__header {
    border: 0;
    padding: 16px 24px;
    display: flex;
    width: 100%;
    text-align: left;
    background-color: white;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.xvia-accordion__header .xvia-title-h {
    flex-grow: 1;
}

.xvia-accordion__content {
    max-height: 0;
    padding: 0 16px;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
    font-family: var(--xvia-font-family);
    color: var(--xvia-color-text);
    background-color: white;
    margin-bottom: 8px;
}

.xvia-accordion__item--active .xvia-accordion__content {
    max-height: 500px;
    padding-bottom: 16px;
}

/*
====> CARD COMPONENT
*/
.xvia-department-section-card {
    padding: 18px;
    padding-bottom: 38px;
    background-color: #ccc;
    max-width: 500px;
    position: relative;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
    border-radius: 15px;
    width: 100%;
    text-align: left;
    border: none;
    cursor: pointer;
}

.xvia-department-section-card iframe {
    border: 1px solid #efefef;
    margin-top: 20px;
    width: 100%;
}

/*
====> CARD COMPONENT
*/

.xvia-card {
    border: 2px solid #f3f3f3;
    padding: 18px;
    padding-bottom: 38px;
    background-color: white;
}

.xvia-card__title {
    line-height: 1;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 30px;
    padding-bottom: 20px;
    color: #181b1d;
    border-bottom: 1px solid #f1f1f1;
}

.xvia-card__title span {
    color: var(--xvia-color-primary);
}

.xvia-card-section__map {
    border: 0px;
    width: 100%;
    height: 300px;
    padding: 30px 10px;
}

.xvia-card__borderless-bottom {
    border-bottom: unset;
    padding-bottom: 18px;
}

/*
====> SERVICE COMPONENT
*/

.xvia-service-card {
    position: relative;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
    border-radius: 15px;
    background: #fff;
    width: 100%;
    text-align: left;
    border: none;
    cursor: pointer;
    padding: 0;
    max-width: 500px;
    height: 100%;
}

.xvia-service-card---focused {
    transform: scale(1.01);
}

.xvia-service-card__content {
    position: relative;
    padding: 27px 30px 15px;
    border-radius: 0 0 15px 15px;
}

.xvia-service-card__icon {
    width: 50px;
    height: 50px;
    text-align: center;
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.04);
    position: absolute;
    right: 26px;
    top: 0;
    transform: translateY(-50%);
    z-index: 2;
    background: #fff;
    line-height: 50px;
    border-radius: 15px;
    color: var(--xvia-color-primary);
    font-size: 26px;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
}

.xvia-service-card__rating {
    font-size: 12px;
    color: var(--xvia-color-primary);
    margin-bottom: 10px;
}

.xvia-service-card__title {
    margin-bottom: var(--xvia-spacing-extra-large);
    display: -webkit-box;
    /*max-width: 200px;*/
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 110px;
}

.xvia-service-card__bottom {
    border-top: 1px solid #f1f1f1;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.xvia-service-card__bottom-text {
    flex: 1;
    margin-top: 8px;
    margin-bottom: 4px;
}

.xvia-service-card:hover .xvia-service-card__icon {
    background: #244b9e;
    color: #fff;
}

.xvia-service-card__bottom-icon {
    color: var(--xvia-color-primary);
}

.xvia-service-card__thumb {
    overflow: hidden;
    border-radius: 15px 15px 0 0;
}

.xvia-service-card__thumb img {
    transition: all 0.3s linear 0s;
    max-width: 100%;
}

.xvia-service-card__thumb:hover img {
    transform: scale(1.1);
}

.xvia-service-card__tag {
    border: none;
    color: #777777;
    border-radius: 10px;
    background-color: white;
}

.xvia-service-card__tag.Cidadão {
    color: #093089;
    border: 0.5px solid #093089;
}

.xvia-service-card__tag.Empresa {
    color: #0661A7;
    border: 0.5px solid #0661A7;
}

.xvia-service-card__tag.Servidor {
    color: #02ADD5;
    border: 0.5px solid #02ADD5;
}

.xvia-service-card__tag.Gestão-Pública {
    color: #34D5AA;
    border: 0.5px solid #34D5AA;
}

/*
====> LISTING COMPONENT
*/

.xvia-listing-card {
    position: relative;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.04);
    border-radius: 5px 5px 0 0;
    background: #fff;
    width: 100%;
    text-align: left;
    border: none;
    cursor: pointer;
    padding: 0;
    max-width: 500px;
}

.xvia-listing-card__line {
    max-width: 100%;
}

.xvia-listing-card---focused {
    transform: scale(1.01);
}

.xvia-listing-card__content {
    position: relative;
    padding: 27px 30px 15px;
    border-radius: 0 0 15px 15px;
}

.xvia-listing-card__line .xvia-listing-card__content {
    width: 100%;
}

.xvia-listing-card__header-buttons {
    position: absolute;
    right: 30px;
    top: 0;
    transform: translateY(-50%);
    z-index: 3;
}

.xvia-listing-card__rating {
    font-size: 12px;
    color: var(--xvia-color-primary);
    margin-bottom: 10px;
}

.xvia-listing-card__title {
    margin-bottom: var(--xvia-spacing-tight);
}

.xvia-listing-card__info {
    margin: 4px 0;
}

.xvia-listing-card__bottom {
    border-top: 1px solid #f1f1f1;
}

.xvia-listing-card__bottom-text {
    flex: 1;
    margin-top: 8px;
    margin-bottom: 4px;
}

.xvia-listing-card__bottom-button {
    max-width: 70%;
    margin-left: 15%;
}

.xvia-listing-card:hover .xvia-listing-card__icon {
    background: #244b9e;
    color: #fff;
}

.xvia-listing-card__bottom-icon {
    color: var(--xvia-color-primary);
}

.xvia-listing-card__subtitle {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}

/*
====> STAR
*/

.xvia-rating {
    color: var(--xvia-color-primary);
}

.xvia-rating__icon {
    margin-right: 4px;
    transition: opacity 0.2s ease-in-out;
}

.xvia-rating__icon--disabled {
    opacity: 0.1;
}

.xvia-rating__icon--clickable {
    cursor: pointer;
}

/*
====> LIKE BUTTON
*/

.xvia-icon-button {
    color: #fff;
    background: var(--xvia-color-primary);
    padding: 8px;
    line-height: 12px;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    transition: all 0.2s linear 0s;
}

.xvia-icon-button:hover {
    transform: scale(1.5);
}

.xvia-icon-button--flat {
    margin-right: 4px;
    background: transparent;
    color: var(--xvia-color-primary);
}

/*
====> NEWS CARD
*/

.xvia-news-card {
    position: relative;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
    border-radius: 15px;
    background: #fff;
    width: 100%;
    text-align: left;
    border: none;
    cursor: pointer;
    padding: 0;
    max-width: 500px;
    overflow: hidden;
}

.xvia-news-card__thumb {
    overflow: hidden;
    border-radius: 15px 15px 0 0;
    height: 200px;
    background-size: cover;
    background-position: center;
}

.xvia-news-card__thumb {
    transition: all 0.3s linear 0s;
    align-items: flex-end;
    display: flex;
}

.xvia-news-card__thumb:hover {
    transform: scale(1.1);
}

.xvia-news-card__content {
    background: #0000009c;
    height: 80px;
    position: relative;
    color: white;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    /* overflow: hidden; */
    padding: 15px;
    line-height: initial;
    font-weight: bold;
    margin-top: -80px;
}

.xvia-news-card__content p {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.xvia-news-card__category {
    position: absolute;
    bottom: 100%;
    left: 30px;
    background: #244b9e;
    padding: 10px 15px;
    font-size: 14px;
    color: #fff;
    border-radius: 15px 15px 0 0;
    max-width: 50%;
}

.xvia-news-card__date {
    color: #777777;
    padding: 15px 0px;
}

.xvia-news-card__title {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 110px;
}


/*
====> CATALOG CARD
*/

.xvia-catalog-card {
    border-radius: 15px;
    background: #fff;
    width: 100%;
    padding: 50px;
}

.xvia-catalog-card__title_content {
    display: inline-block;
}

.xvia-catalog-card__info_content span {
    display: inline-block;
    padding: 5px;
}

/*
====> FORM
*/

.xvia-form-input {
    height: 65px;
    width: 100%;
    background-color: #f4f4f4;
    border: 0;
    padding: 0 30px;
    font-size: 16px;
    color: #a7a7a7;
    border-radius: 16px;
    transition: opacity 0.3s linear 0s;
}

.xvia-form-input--disabled {
    opacity: 0.4;
}

/*
====> SELECT
*/

.xvia-form-input-select {
    height: 65px;
    width: 100%;
    background-color: #f4f4f4;
    border: 0;
    padding: 0 30px;
    font-size: 16px;
    color: #a7a7a7;
    border-radius: 16px;
    transition: opacity 0.3s linear 0s;
    -moz-appearance: none;
    /* Firefox */

    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%),
        radial-gradient(#ddd 70%, transparent 72%);
    background-position: calc(100% - 20px) calc(1em + 15px),
        calc(100% - 15px) calc(1em + 15px), calc(100% - 0.5em) calc(1em + 4px);
    background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
    background-repeat: no-repeat;
}

.xvia-form-input-select:focus {
    background-image: linear-gradient(45deg, white 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, white 50%),
        radial-gradient(gray 70%, transparent 72%);
    background-position: calc(100% - 15px) calc(1em + 14px),
        calc(100% - 20px) calc(1em + 14px), calc(100% - 0.5em) calc(1em + 4px);
    background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
    background-repeat: no-repeat;
    border-color: green;
    outline: 0;
}

/*
====> INFO
*/

.xvia-info {
    display: inline-block;
}

.xvia-info__icon {
    color: var(--xvia-color-primary);
    margin-right: 4px;
    font-size: 14px;
}

.xvia-info__text {
    font-family: var(--xvia-font-family);
    font-size: 14px;
    color: var(--xvia-color-text);
    font-weight: 500;
}

/*
====> BREAK PAGE
*/

.xvia-break-page {
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.xvia-break-page__icon {
    height: 20px;
    width: 5px;
    margin-right: 10px;
}

.xvia-break-page__text {
    font-family: var(--xvia-font-family);
    font-size: 22px;
    color: var(--xvia-color-text);
    font-weight: bold;
    margin-right: 20px;
}

.xvia-break-page__line {
    color: var(--xvia-color-text);
    width: 70%;
    background-color: #ccc;
    height: 2px;
}

/*
====> DOCUMENT LIST
*/

.xvia-document-list {
    list-style-type: none;
    padding-left: 0;
}

.xvia-document-list .xvia-info__icon {
    margin-right: 18px;
}

.xvia-document-list__item {
    padding: 16px;
    border-bottom: 1px solid #efefef;
    font-weight: 800;
}

.xvia-document-list__item:last-child {
    border-bottom: none;
}

/*
====> INFO CARD
*/

.xvia-metrics-card {
    padding: 40px;
    border-radius: 15px;
    background: #fff;
    text-align: center;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    box-shadow: 0px 16px 32px 0px rgb(171 171 171 / 10%);
    font-family: var(--xvia-font-family);
    color: var(--xvia-color-text);
    max-width: 500px;
}

.xvia-metrics-card__icon {
    width: 40px;
    height: 40px;
    line-height: 95px;
    font-size: 10px;
    color: #fff;
    background: var(--xvia-color-primary);
    margin: 0 auto 20px;
    border-radius: 50%;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    font-weight: 200;
    padding: 25px;
}

.xvia-metrics-card__text {
    color: #181b1d;
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 0;
    line-height: 38px;
    margin-top: 0px;
}

.xvia-metrics-card:hover {
    background: var(--xvia-color-primary);
    color: #ffffff;
}

.xvia-metrics-card:hover .xvia-metrics-card__icon {
    background: #fff;
    color: var(--xvia-color-primary);
}

.xvia-metrics-card:hover .xvia-metrics-card__icon {
    color: #b2b2b2;
}

/*
====> CATEGORY CARD
*/

.xvia-category-card {
    padding: 15px 20px;
    border-radius: 15px;
    background: #fff;
    text-align: center;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    box-shadow: 0px 16px 32px 0px rgb(171 171 171 / 10%);
    font-family: var(--xvia-font-family);
    color: var(--xvia-color-text);
    width: 200px;
    height: 150px;
    display: inline-block;
    margin: 10px;
    cursor: pointer;
}

.xvia-category-card__icon {
    width: 20px;
    height: 20px;
    line-height: 85px;
    font-size: 10px;
    color: #fff;
    background: var(--xvia-color-primary);
    margin: 0 auto 10px;
    border-radius: 50%;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    font-weight: 200;
    padding: 25px;
}

.xvia-category-card:hover {
    background: var(--xvia-color-primary);
    color: #ffffff;
}

.xvia-category-card:hover .xvia-category-card__icon {
    background: #fff;
    color: var(--xvia-color-primary);
}

.xvia-category-card:hover .xvia-category-card__icon {
    color: #b2b2b2;
}

/*
====> CAROUCEL COMPONENT
*/

.xvia-carousel .slick-list {
    padding: 20px 0px;
    overflow: hidden;
}

.slick-next:before,
.slick-prev:before {
    font-size: 30px;
    line-height: 1;
    opacity: 0.75;
    color: #6e6e6e;
}

.xvia-carousel .slick-slide {
    display: flex;
}


.xvia-carousel-icon {
    font-size: 24px;
    color: #c2c2c2;
}

.xvia-carousel-icon:hover {
    font-size: 24px;
    color: var(--xvia-color-primary);
}

/*.xvia-carousel .slick-arrow{*/
/*    font-size: 0;*/
/*    line-height: 0;*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    display: block;*/
/*    width: 20px;*/
/*    height: 20px;*/
/*    padding: 0;*/
/*    -ms-transform: translateY(-50%);*/
/*    transform: translateY(-50%);*/
/*    cursor: pointer;*/
/*    border: none;*/
/*}*/
/*.xvia-carousel{}*/
/* .slick-next {*/
/*     right: -25px;*/
/* }*/
/*.slick-prev {*/
/*    left: -25px;*/
/*}*/

/*
====> SEARCH INPUT
*/

.xvia-search-input {
    background: #fff;
    padding: 4px 4px;
    border-radius: 15px;
}

.xvia-search-input__input {
    width: 100%;
    padding-left: 30px;
    font-size: 14px;
    font-weight: 400;
    color: #c5c5c5;
    border: none;
}

.xvia-search-input__button {
    height: 48px;
    line-height: 48px;
    font-size: 14px;
}

@media screen and (max-width: 768px) {
    .xvia-search-input__input {
        padding-left: 12px;
        margin: 12px 0;
    }
}

@media (min-width: 768px) {
    div.xvia-search-input__button-wrapper {
        flex: 0 0 100px;
    }
}

/*
====> SOCIAL MIDIA
*/

.xvia-social-midia__icon {
    color: var(--xvia-color-primary);
}

.xvia-social-midia__content_icon {
    background-color: #ffffff;
    padding: 5px 8px;
    border-radius: 4px;
    margin: 4px;
    width: 32px;
    height: 32px;
    display: inline-block;
    text-align: center;
}

.xvia-social-midia__text {
    color: #ffffff;
}

.xvia-social-midia__content_icon:first-child {
    margin-left: 0px;
}

/*
====> LINK LIST
*/

.xvia-link-list {}

.xvia-link-list__title {
    color: white;
}

.xvia-link-list__text {
    color: white;
}

.xvia-link-list__ul {
    list-style: none;
    color: white;
    padding: 5px;
}

.xvia-link-list__icon {
    margin-right: 10px;
}

.xvia-social-midia__content_icon {
    background-color: #ffffff;
    padding: 5px 8px;
    border-radius: 4px;
    margin: 4px;
    width: 32px;
    height: 32px;
    display: inline-block;
    text-align: center;
}

.xvia-social-midia__text {
    color: #ffffff;
}

.xvia-social-midia__content_icon:first-child {
    margin-left: 0px;
}

.xvia-app-card {
    position: relative;
    border-radius: 15px;
    background-color: white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /*max-width: 200px;*/
    min-height: 200px;
    /*padding: 60px;*/
}

.xvia-app-card__content {
    visibility: hidden;
    padding: 25px;
    /*max-width: 200px;*/
    min-height: 200px;
    border-radius: 15px;
    text-align: center;
    color: #efefef;
}

.xvia-app-card:hover div {
    visibility: initial;
    background-color: var(--xvia-color-primary);
}

.xvia-app-card__title {
    color: white;
    margin: 10px 0px;
}

.xvia-app-card__icon {
    font-size: 33px;
    color: white;
}

/*
====> BREADCRUMB COMPONENT
*/
.xvia-breadcrumb {
    background: inherit;
}

/*
====> CONTAINER
*/
.xvia-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .xvia-container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .xvia-container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .xvia-container {
        width: 1170px;
    }
}

/*********
 MODULES
**********/
.xvia-home-search {
    background-color: var(--xvia-color-primary);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.xvia-home-search__container {
    padding: 120px 40px;
}

.xvia-home-search__gradient {
    width: 100%;
    height: 15px;
    background: rgb(8, 57, 143);
    background: linear-gradient(90deg, rgba(8, 57, 143, 1) 0%, rgba(6, 96, 167, 1) 17%, rgba(3, 173, 213, 1) 38%, rgba(51, 213, 170, 1) 58%, rgba(139, 235, 105, 1) 80%, rgba(185, 251, 17, 1) 100%);
}

.xvia-home-search__links {
    background-color: rgba(24, 27, 29, 0.8);
    padding-bottom: 20px;
    border-radius: 10px;
    padding: 7px;
    font-size: 13px;
}

.xvia-home-search__links,
.xvia-home-search__links a {
    color: white;

}

.xvia-home-search__links span {
    cursor: pointer;
}

.xvia-home-search .xvia-home-search__links {
    display: none;
    justify-content: center;
    width: 100%;
    margin-top: 16px;
}

.xvia-home-search__input input:focus {
    outline: none;
}

.xvia-home-search__input input {
    height: 45px;
}

@media (min-width: 768px) {
    .xvia-home-search__input {
        max-width: 66%;
        margin: auto;
    }

    .xvia-home-search .xvia-home-search__links {
        display: inline-flex;
    }

    .xvia-home-search__links {
        max-width: 66%;
        margin: auto;
    }
}

/*********
 MODULES - Recommended Services
**********/

.xvia-recommended-services {
    padding: 60px;
    background-color: white;
}

.xvia-recommended-services__container {
    text-align: center;
}

.xvia-recommended-services__title {
    font-size: 64px;
    color: #181b1d;
    margin-bottom: 40px;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .xvia-recommended-services__title {
        font-size: 60px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .xvia-recommended-services__title {
        font-size: 50px;
    }
}

@media (max-width: 767px) {
    .xvia-recommended-services__title {
        font-size: 40px;
    }
}

.xvia-recommended-services__subtitle {
    color: #777777;
    font-size: 16px;
    display: block;
    font-weight: 700;
    margin-bottom: 0px;
}

.xvia-recommended-services__card {
    display: inline-block;
    margin: 0px 10px;
    text-align: center;
}

/*********
 MODULES - Relevant Services
**********/

.xvia-relevant-services {
    background-color: white;
    padding: 20px;
}

.xvia-relevant-services .slick-slide {
    padding: 20px;
    vertical-align: top;
}

.xvia-relevant-services .slick-arrow {
    color: #bababa !important;
    /*background: #181b1d !important;*/
}

.xvia-relevant-services__container {
    text-align: center;
}

.xvia-relevant-services__title {
    font-size: 64px;
    color: #181b1d;
    margin-bottom: 40px;
}

.xvia-relevant-services__loading {
    height: 317px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.xvia-top-services {
    /*background-color: #f8f8f8;*/
}

@media (min-width: 992px) and (max-width: 1199px) {
    .xvia-relevant-services__title {
        font-size: 60px;
    }

    .xvia-relevant-services {
        padding: 60px 30px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .xvia-relevant-services__title {
        font-size: 50px;
    }

    .xvia-relevant-services {
        padding: 60px 2px;
    }
}

@media (max-width: 767px) {
    .xvia-relevant-services__title {
        font-size: 40px;
    }

    .xvia-relevant-services {
        padding: 60px 0px;
    }
}

.xvia-relevant-services__subtitle {
    color: #777777;
    font-size: 16px;
    display: block;
    font-weight: 700;
    margin-bottom: 0px;
}

.xvia-relevant-services__card {
    display: inline-block;
    margin: 0px 10px;
    text-align: center;
    vertical-align: top !important;
}

/*********
 MODULES - Relevant Services
**********/

.xvia-news {
    padding: 20px;
    background-color: white;
}

.xvia-news__container {
    text-align: center;
}

.xvia-news__container .slick-slide {
    padding: 20px;
    vertical-align: top;
}

.xvia-news__title {
    font-size: 64px;
    color: #181b1d;
    margin-bottom: 40px;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .xvia-news__title {
        font-size: 60px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .xvia-news__title {
        font-size: 50px;
    }
}

@media (max-width: 767px) {
    .xvia-news__title {
        font-size: 40px;
    }
}

.xvia-news__subtitle {
    color: #777777;
    font-size: 16px;
    display: block;
    font-weight: 700;
    margin-bottom: 0px;
}

.xvia-news__card {
    display: inline-block;
    margin: 0px 10px;
    text-align: center;
}

/*********
 MODULES - Relevant Services
**********/

.xvia-indicators {
    padding: 60px 60px 0px 60px;
    background-color: var(--xvia-color-primary);
    height: 245px;
}

.xvia-indicators__container {
    text-align: center;
}

.xvia-indicators__title {
    font-size: 64px;
    color: #ffffff;
    margin-bottom: 40px;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .xvia-indicators__title {
        font-size: 60px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .xvia-indicators__title {
        font-size: 50px;
    }
}

@media (max-width: 767px) {
    .xvia-indicators__title {
        font-size: 40px;
    }

    .xvia-indicators {
        height: auto;
        padding-bottom: 20px;
    }
}

.xvia-indicators__subtitle {
    color: #ffffff;
    font-size: 16px;
    display: block;
    font-weight: 700;
    margin-bottom: 0px;
}

.xvia-indicators__card {
    display: inline-block;
    margin: 0px 10px;
    text-align: left;
}

/*********
 MODULES - Apps
**********/

.xvia-apps {
    margin-bottom: 40px;
    padding: 40px;
    background-color: white;
}

.xvia-apps__container {
    text-align: center;
}

.xvia-apps__title {
    font-size: 64px;
    color: #181b1d;
    margin-bottom: 40px;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .xvia-apps__title {
        font-size: 60px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .xvia-apps__title {
        font-size: 50px;
    }
}

@media (max-width: 767px) {
    .xvia-apps__title {
        font-size: 40px;
    }
}

.xvia-apps__subtitle {
    color: #777777;
    font-size: 16px;
    display: block;
    font-weight: 700;
    margin-bottom: 0px;
}

.xvia-apps__card {
    display: inline-block;
    margin: 0px 10px;
    text-align: center;
}

/*********
 MODULES - Ombudsman
**********/

.xvia-ombudsman {
    margin-top: 80px;
    padding: 60px;
    background-color: white;
}

.xvia-ombudsman__container {
    text-align: center;
}

.xvia-ombudsman__title {
    font-size: 64px;
    color: #181b1d;
    margin-bottom: 40px;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .xvia-ombudsman__title {
        font-size: 60px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .xvia-ombudsman__title {
        font-size: 50px;
    }
}

@media (max-width: 767px) {
    .xvia-ombudsman__title {
        font-size: 40px;
    }
}

.xvia-ombudsman__subtitle {
    color: #777777;
    font-size: 16px;
    display: block;
    font-weight: 700;
    margin-bottom: 0px;
}

.xvia-ombudsman__card {
    display: inline-block;
    margin: 0px 10px;
    text-align: center;
}

/*********
 MODULES - Footer
**********/

.xvia-footer {
    margin-top: 100px;
    padding: 60px;
    background-color: var(--xvia-color-primary);
}

.xvia-footer__container {
    text-align: left;
}

.xvia-footer__title {
    font-size: 64px;
    color: #181b1d;
    margin-bottom: 40px;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .xvia-footer__title {
        font-size: 60px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .xvia-footer__title {
        font-size: 50px;
    }
}

@media (max-width: 767px) {
    .xvia-footer__title {
        font-size: 40px;
    }

    .xvia-footer__card {
        text-align: center;
    }
}

.xvia-footer__subtitle {
    color: #777777;
    font-size: 16px;
    display: block;
    font-weight: 700;
    margin-bottom: 0px;
}

.xvia-footer__card {
    display: inline-block;
    margin: 0px 10px;
    text-align: left;
}

.xvia-footer__logo {
    margin-bottom: 40px;
}

/*********
 MODULES - LinkBar
**********/

.xvia-link-bar {
    padding: 20px;
}

.xvia-link-bar ul {
    justify-content: center;
}

.xvia-link-bar__card {
    text-align: center;
}

/*********
 MODULES - Footer
**********/

.xvia-catalog {}

.xvia-catalog__breadcrumb {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #fff;
}

.xvia-catalog__hearder_container {
    height: 120px;
    background-color: var(--xvia-color-primary);
    width: 100%;
    margin-bottom: -100px;
    padding-top: 130px;
}

.xvia-catalog__info_section {
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #efefef;
}

.xvia-catalog__info_section h2 {
    padding-bottom: 15px;
}

.xvia-catalog__info_row {
    word-break: break-word;
    margin-bottom: 30px;
}

.xvia-catalog__section_list {
    max-height: 200px;
    overflow: auto;
    width: 100%;
    padding: 20px;
}

.xvia-catalog__info_row_cannel {
    font-size: 20px;
    color: var(--xvia-color-primary);
    margin-bottom: 20px;
    border-bottom: 1px solid #eaeaea;
    padding-left: 10px;
    padding: 10px;
}

.xvia-catalog__info_row_cannel span {
    font-size: 14px;
    color: var(--xvia-color-text);
}

/*********
 MODULES - Category
**********/

.xvia-category {}

.xvia-category .slick-list {
    padding: 25px 0px;
}

.xvia-category-services__container {
    text-align: center;
}

.xvia-category-services__title {
    font-size: 64px;
    color: #181b1d;
    margin-bottom: 40px;
}

.xvia-category-services__subtitle {
    color: #777777;
    font-size: 16px;
    display: block;
    font-weight: 700;
    margin-bottom: 0px;
}

/*********
 MODULES - List
**********/

.xvia-list {
    margin-top: 0px;
}

.xvia-list__breadcrumb {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #fff;
}

.xvia-list__head_info {
    background-size: cover;
    background-position: center;
    opacity: 0.3;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.xvia-list__head_content {
    /*height: 180px;*/
    overflow: hidden;
    position: relative;
    background-color: var(--xvia-color-primary);
    margin-bottom: 60px;
}

.xvia-list__head_info_container {
    padding: 30px 0px;
    position: relative;
}

.xvia-list__head_info__title p {
    font-size: 20px;
    margin-top: -25px;
}

.xvia-list__head_info__title {
    font-size: 64px;
    margin-bottom: 0px;
    color: white;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .xvia-list__head_info__title {
        font-size: 60px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .xvia-list__head_info__title {
        font-size: 50px;
    }
}

@media (max-width: 767px) {
    .xvia-list__head_info__title {
        font-size: 40px;
    }
}

.xvia-list__head_info__subtitle {
    color: white;
    font-size: 16px;
    display: block;
    font-weight: 700;
    margin-bottom: 0px;
}

/*********
 MODULES - Department
**********/

.xvia-department {}

.xvia-department__breadcrumb {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #fff;
}

.xvia-department__hearder_container {
    height: 120px;
    background-color: var(--xvia-color-primary);
    width: 100%;
    margin-bottom: -100px;
}

.xvia-department__info_section {
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #efefef;
}

.xvia-department__info_section h2 {
    padding-bottom: 15px;
}

.xvia-department__info_row {
    margin-bottom: 30px;
}

.xvia-department__section_content {
    margin-top: 20px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
}

.xvia-department__pagination {
    padding-top: 40px;
}

/*
====> MODAL REVIEW
*/
.modal-review__button {
    font-family: var(--xvia-font-family);
    color: #fff;
    border: 0;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
}

.modal-review__button--rounded {
    border-radius: 15px;
}

.modal-review__button--primary {
    background: var(--xvia-color-primary);
}

.modal-review__button--secondary {
    color: var(--xvia-color-text-secondary);
}

.modal-review__button:hover {
    background: var(--xvia-color-highlight);
}

.modal-review__button--secondary:hover {
    background: var(--xvia-color-gray);
    color: var(--xvia-color-text-secondary);
}

.modal-review__rating {
    display: flex;
    column-gap: 10px;
}

.modal-review__comment {
    margin-top: 15px;
}

.rematricula-popup {
    width: 100%;
    height: 100%;

    max-height: 580px;
}

.rematricula-popup .ant-modal-body {
    padding: 0;
}

.rematricula-popup .ant-modal-close {
    align-items: flex-start;
}

.rematricula-popup .ant-modal-close-x {
    margin-top: -40px;
    color: white;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    padding-right: 10px;
}

.rematricula-popup__content {
    width: 100%;
    height: 100%;
}

.rematricula-popup__content img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}